import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextArea.figmaUrl.android} codeUrl={checklists.componentTextArea.codeUrl.android} checklists={checklists.componentTextArea.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Textarea allow user input. The border should light up simply and clearly indicating which field the user is currently editing.`}</p>
    <div className="divi" />
    <h3><strong parentName="h3">{`Basic`}</strong></h3>
    <p>{`Here’s the default usage of Textarea with placeholder and label attribute set.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/android-1.png",
      "alt": "basic textarea"
    }}></img>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnTextAreaField
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:hint="Catatan"
    app:placeholderText="Tambahkan Catatan" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnTextAreaField(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3><strong parentName="h3">{`Required Text Area`}</strong></h3>
    <p>{`Add required status to make it easier for users to know which fields are required status, the label will automatically be added with a red asterisk mark`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/android-2.png",
      "alt": "required text area"
    }}></img>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnTextAreaField
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:hint="Catatan"
    app:isRequired="true"
    app:placeholderText="Tambahkan Catatan" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnTextAreaField(requiredContext()).apply {
            isRequired = true
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3><strong parentName="h3">{`Optional Text Area`}</strong></h3>
    <p>{`Add optional status to make it easier for users to know which fields are optional, labels will be automatically added text (Optional) in various languages`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/android-3.png",
      "alt": "optional textarea"
    }}></img>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnTextAreaField
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:hint="Catatan"
    app:isOptional="true"
    app:placeholderText="Tambahkan Catatan" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnTextAreaField(requiredContext()).apply {
            isOptional = true
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3><strong parentName="h3">{`Disabled Text Area`}</strong></h3>
    <p>{`Make textarea look inactive.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/android-4.png",
      "alt": "disabled textarea"
    }}></img>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnTextAreaField
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:hint="Catatan"
    android:enabled="false"
    app:placeholderText="Tambahkan Catatan" />
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnTextAreaField(requiredContext()).apply {
            isEnable = false
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3><strong parentName="h3">{`Error Indicator Text Area`}</strong></h3>
    <p>{`Add an error indicator to make it easier for users to find out which fields are wrong in inputting, and provide error messages in the relevant fields`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/android-5.png",
      "alt": "error indicator textarea"
    }}></img>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnTextAreaField
    android:id="@+id/etAreaError"
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:hint="Catatan"
    app:placeholderText="Tambahkan Catatan" />
`}</code></pre>
    <h4>{`In Your Kotlin Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`...
with(binding) {
    etAreaError.error = "Your error message"
}
...
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnTextAreaField(requiredContext()).apply {
            error = "Your error message"
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TextView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tvHint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display label on text area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TextInputLayout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`etBase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display text field on text area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TextView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tvHelper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display helper text on text area`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TextView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tvError`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display error text on text area`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Hint or Label value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Required Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set required status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set optional status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:placeholderText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`placeHolder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set placeholder text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:placeholderText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set helper text directly via xml`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      